<template id="modalConfirm">
  <v-dialog v-model="status_dialog" :max-width="max_width" persistent>
    <v-card>
      <v-card-title class="text-subtitle-1">{{ message }}</v-card-title>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="red darken-1" text @click="cancel">Cancelar</v-btn>
        <v-btn color="green darken-1" text @click="confirm">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modalConfirm",
  props: {
    message: {
      type: String,
      default: "No se ha definido un mensaje",
    },

    estado: {
      type: Boolean,
      default: false,
    },

    callback: {
      type: Function,
    },

    max_width: {
      type: String,
      default: "430px",
    },
  },

  computed: {
    status_dialog: function () {
      return this.estado;
    },
  },

  methods: {
    cancel() {
      this.$emit("close", false);
    },
    confirm() {
      if (this.callback) this.callback();
    },
  },
};
</script>